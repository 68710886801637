import { Badge, Button, IconButton, Menu, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useCommunities from '../../hooks/useCommunities';
import MultiselectListBox from '../MultiselectListBox';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { useApplicationContext } from '../../ApplicationContext';
import { useReportContext } from '../../contexts/ReportContext';
import Box from '@mui/material/Box';

const GlobalCommunityFilter = () => {
    const { selectedCompanyId } = useApplicationContext();
    const { globalFilter, setGlobalFilterValue } = useReportContext();
    const { communities } = useCommunities(true);
    const [selectedCommunities, setSelectedCommunities] = useState<any>(globalFilter.communities?.map((c) => c.id) || []);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [error, setError] = useState(null);

    useEffect(() => {
        setSelectedCommunities([]);
    }, [selectedCompanyId]);

    useEffect(() => {
        setSelectedCommunities(globalFilter.communities?.map((c) => c.id) || []);
    }, [globalFilter.communities, setSelectedCommunities]);

    // TODO: move apply button out when we have more than one filter and have this component onChange the global filter
    const apply = () => {
        if (selectedCommunities.length === 0) {
            setError('Please select at least one community to view');
            return;
        }

        setError(null);
        setAnchorEl(null);
        setGlobalFilterValue(
            'communities',
            selectedCommunities.map((id) => communities.find((c) => c.id === id)),
        );
    };

    const cancel = () => {
        setAnchorEl(null);
        setSelectedCommunities(globalFilter.communities?.map((c) => c.id) || []);
    };

    return (
        <>
            {isMobile ? (
                <Badge badgeContent={selectedCommunities.length} invisible={selectedCommunities.length === 0} color="primary">
                    <IconButton
                        size={'small'}
                        disabled={communities.length === 0}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                        aria-label="community-select"
                        color={'primary'}
                    >
                        <FilterListRoundedIcon fontSize={'medium'} />
                    </IconButton>
                </Badge>
            ) : (
                <TextField
                    sx={{ width: 225 }}
                    aria-label="community-select"
                    size={'small'}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        setAnchorEl(event.currentTarget);
                    }}
                    spellCheck={false}
                    disabled={selectedCommunities.length === 0}
                    placeholder="Select Communities"
                    value={
                        selectedCommunities.length === 1
                            ? communities.find((c) => c.id === selectedCommunities[0])?.name
                            : selectedCommunities.length === 0
                            ? 'Select Communities'
                            : `${selectedCommunities.length} Communities`
                    }
                />
            )}
            <Menu
                disableAutoFocusItem
                id="community-filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={cancel}
                sx={{
                    display: 'flex',
                }}
            >
                <MultiselectListBox
                    listContainerProps={{
                        sx: {
                            height: '60vh',
                            overflow: 'scroll',
                        },
                    }}
                    containerStyle={{ border: 'none' }}
                    options={communities.map((c) => ({ label: c.name, value: c.id }))}
                    selectedOptions={selectedCommunities}
                    setSelectedOptions={setSelectedCommunities}
                />
                <Box sx={{ px: 2, pb: 1, pt: error ? 1 : 2, borderTop: '1px solid #ced4da' }}>
                    <Typography sx={{ pb: 1 }} color={'error'} variant={'subtitle2'}>
                        {error}
                    </Typography>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Button variant={'outlined'} onClick={cancel}>
                            Cancel
                        </Button>
                        <Button variant={'contained'} onClick={() => apply()}>
                            Apply
                        </Button>
                    </Stack>
                </Box>
            </Menu>
        </>
    );
};

export default GlobalCommunityFilter;
