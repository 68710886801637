import React, { useCallback, useMemo } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { navData, NavDataItem } from '../lib/navData';
import EditSubscriptionButton from './Subscriptions/EditSubscriptionButton';
import { GLOBAL_FILTER_PAGES, SUBSCRIPTION_TYPES } from './Subscriptions/subscriptionConstants';
import { Breadcrumbs, IconButton, Link, Stack } from '@mui/material';
import ReportExportButton from './Report/ReportExportButton';
import ReportCommentButton from './Report/ReportCommentButton';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import './BreadCrumbs.css';
import Box from '@mui/material/Box';
import GlobalFilters from './GlobalFilters/GlobalFilters';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';

export type BreadCrumbItem = {
    label: string;
    url: string;
};

const BreadCrumbs = () => {
    const matches = useMatches();
    const navigate = useNavigate();

    const reportId = useMemo(() => {
        return matches.at(-1)?.params.id;
    }, [matches]);

    const getIcon = (key: string) => {
        return navData.find((item: NavDataItem) => key.split('/')[1] === item.link.substring(1))?.icon;
    };

    const crumbs = useMemo(() => {
        return matches
            .filter((match) => Boolean((match.handle as any)?.crumb))
            .map((match, index) => {
                let crumb = (match.handle as any).crumb(match);
                crumb.command = () => navigate(match.pathname);
                if (index === 0 && !crumb.icon) {
                    crumb.icon = getIcon(match.pathname);
                }
                return crumb;
            });
    }, [matches, navigate]);

    const subscriptionType = useMemo(() => {
        if (crumbs[0]?.label) {
            return SUBSCRIPTION_TYPES.find((type) => crumbs[0].label.includes(type));
        }
    }, [crumbs]);

    const showGlobalFilter = useMemo(() => {
        if (crumbs[0]?.label) {
            return GLOBAL_FILTER_PAGES.some((type) => crumbs[0].label.includes(type));
        }
    }, [crumbs]);

    const SubscriptionButton = useCallback(
        ({ size = 'medium' }: { size?: 'small' | 'medium' | 'large' }) => {
            return (
                <EditSubscriptionButton
                    ButtonComponent={(props) => {
                        let isSubscribed = props.isSubscribed;

                        return (
                            <IconButton
                                size={size}
                                disabled={props.disabled}
                                onClick={props.onClick}
                                aria-label="subscription-button"
                                color={'primary'}
                            >
                                {!isSubscribed ? <MailOutlineRoundedIcon fontSize={size} /> : <MarkEmailReadRoundedIcon fontSize={size} />}
                            </IconButton>
                        );
                    }}
                    reportId={reportId}
                    isDashboard={subscriptionType === 'Dashboard'}
                />
            );
        },
        [reportId, subscriptionType],
    );

    const renderMobileBreadcrumb = () => {
        if (crumbs.length > 1) {
            return (
                <div className="mobile-previous-page">
                    <Box onClick={() => navigate(crumbs.at(-2).url)} display={'flex'} alignItems={'center'}>
                        <ArrowCircleLeftOutlinedIcon fontSize={'small'} sx={{ marginRight: 1 }} />
                        <span>{crumbs.at(-2).label}</span>
                    </Box>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} className={'bread-crumbs__button-container'}>
                        {SUBSCRIPTION_TYPES.includes(subscriptionType) && crumbs.length >= 2 && <ReportCommentButton reportId={reportId} />}
                        {SUBSCRIPTION_TYPES.includes(subscriptionType) && crumbs.length >= 2 && <ReportExportButton reportId={reportId} />}
                        {SUBSCRIPTION_TYPES.includes(subscriptionType) && crumbs.length >= 2 && <SubscriptionButton size={'medium'} />}
                        {showGlobalFilter && <GlobalFilters />}
                    </Stack>
                </div>
            );
        } else {
            return <></>;
        }
    };

    return crumbs.length ? (
        <>
            <div className="breadcrumbs-wrapper">
                <Breadcrumbs
                    sx={{
                        fontSize: '0.875rem',
                        background: '#ffffff',
                        border: '1px solid #dee2e6',
                        borderRadius: '6px',
                        padding: '1rem',
                        color: '#495057',
                        flexGrow: 1,
                    }}
                >
                    {crumbs[0]?.icon ? (
                        <Link color={'inherit'} sx={{ display: 'flex' }}>
                            {crumbs[0].icon}
                        </Link>
                    ) : (
                        <></>
                    )}
                    {crumbs.map((crumb, index) => {
                        return (
                            <Link
                                key={index}
                                href={'#'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(crumb.url);
                                }}
                                color={'inherit'}
                                underline={'none'}
                            >
                                {crumb.label}
                            </Link>
                        );
                    })}
                </Breadcrumbs>
                <Stack spacing={2} direction={'row'} justifyContent={'center'} alignItems={'center'} className={'bread-crumbs__button-container'}>
                    <Stack spacing={1} direction={'row'}>
                        {SUBSCRIPTION_TYPES.includes(subscriptionType) && crumbs.length >= 2 && <ReportCommentButton reportId={reportId} />}
                        {SUBSCRIPTION_TYPES.includes(subscriptionType) && crumbs.length >= 2 && <ReportExportButton reportId={reportId} />}
                    </Stack>
                    {SUBSCRIPTION_TYPES.includes(subscriptionType) && crumbs.length >= 2 && <SubscriptionButton />}
                    {showGlobalFilter && <GlobalFilters />}
                </Stack>
            </div>
            {renderMobileBreadcrumb()}
        </>
    ) : (
        <></>
    );
};

export default BreadCrumbs;
