import Grid from '@mui/material/Unstable_Grid2';
import { IconButton, Radio, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React from 'react';
import RoleHeader from './RoleHeader';

type UserCommunityAccessTabProps = {
    selectedCommunities: { communityId: number; roleId: number }[];
    selectCommunity: (community: { communityId: number; roleId: number }) => void;
    deselectCommunity: (id: number) => void;
    selectAllCommunities: (roleId: number) => void;
    deselectAllCommunities: () => void;
    companyRoles: any[];
    companyCommunities: any[];
};

const UserCommunityAccessTab = ({
    selectedCommunities,
    selectCommunity,
    deselectCommunity,
    selectAllCommunities,
    deselectAllCommunities,
    companyRoles,
    companyCommunities,
}: UserCommunityAccessTabProps) => {
    return (
        <Grid container marginTop={2}>
            <Grid container direction={'column'} spacing={2} marginRight={6}>
                <Grid>Communities</Grid>
                <Grid
                    container
                    direction={'column'}
                    sx={{
                        border: '1px solid #ced4da',
                        borderRadius: '6px',
                        padding: 0,
                        margin: '8px',
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography sx={{ fontWeight: 'bold' }}>Community Name</Typography>
                                </TableCell>
                                {companyRoles?.map((role) => (
                                    <TableCell key={role.id} padding={'checkbox'}>
                                        <RoleHeader
                                            role={role}
                                            selectAllCommunities={selectAllCommunities}
                                            checked={companyCommunities.every((community) =>
                                                selectedCommunities.some((c) => c.communityId === community.id && c.roleId === role.id),
                                            )}
                                        />
                                    </TableCell>
                                ))}
                                <TableCell padding={'checkbox'} align={'center'}>
                                    <IconButton size={'small'} onClick={deselectAllCommunities}>
                                        <ClearRoundedIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companyCommunities?.map((community) => {
                                return (
                                    <TableRow key={community.id}>
                                        <TableCell>{community.name}</TableCell>
                                        {companyRoles?.map((role) => (
                                            <TableCell key={`${community.id}_${role.id}`} padding={'checkbox'} align={'center'}>
                                                <Radio
                                                    size={'small'}
                                                    checked={
                                                        selectedCommunities?.some((x) => x.communityId === community.id && x.roleId === role.id) ??
                                                        false
                                                    }
                                                    name={`access_level_radio_${community.id}`}
                                                    onChange={() => selectCommunity({ communityId: community.id, roleId: role.id })}
                                                />
                                            </TableCell>
                                        ))}
                                        <TableCell padding={'checkbox'} align={'center'}>
                                            <IconButton size={'small'} onClick={() => deselectCommunity(community.id)}>
                                                <ClearRoundedIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserCommunityAccessTab;
