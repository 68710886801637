import Grid from '@mui/material/Unstable_Grid2';
import { Popover, Radio, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import React, { useState } from 'react';

const RoleHeader = ({ role, selectAllCommunities, checked }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <Grid container wrap={'nowrap'} sx={{ paddingRight: '15px' }}>
            <Grid>
                <Radio size={'small'} onChange={() => selectAllCommunities(role.id)} checked={checked} />
            </Grid>
            <Grid container alignContent={'center'}>
                <Typography sx={{ fontWeight: 'bold' }}>{`${role.roleName}`}</Typography>
            </Grid>
            {role.roleDescription ? (
                <Grid container alignContent={'center'}>
                    <Grid
                        aria-owns={open ? `role-description-popover-${role.id}` : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        container
                        sx={{ marginLeft: '0.25rem' }}
                    >
                        <InfoOutlined fontSize={'small'} />
                    </Grid>
                    <Popover
                        id={`role-description-popover-${role.id}`}
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                        slotProps={{
                            paper: {
                                sx: { maxWidth: '12.5rem' },
                            },
                        }}
                    >
                        <Typography sx={{ p: 1, fontSize: '12pt' }}>{role.roleDescription}</Typography>
                    </Popover>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default RoleHeader;
