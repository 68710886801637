import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet, useMatches, useNavigate } from 'react-router-dom';
import { useApplicationContext } from './ApplicationContext';
import { Layout } from './Layout';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { navData } from './lib/navData';

const ProtectedRoute = () => {
    const { currentUser, hasPermission } = useApplicationContext();
    const matches = useMatches();
    const navigate = useNavigate();
    const [allowed, setAllowed] = useState(true);
    const [loading, setLoading] = useState(true);
    const [requiredPermissions, setRequiredPermissions] = useState(new Set());

    useEffect(() => {
        setRequiredPermissions(new Set());
        let pagePermissions = matches.filter((match) => Boolean((match.handle as any)?.permission));
        if (pagePermissions.length === 0) {
            setLoading(false);
            setAllowed(true);
        } else if (currentUser && Object.keys(currentUser).length > 0) {
            pagePermissions.forEach((e) => {
                if (!hasPermission((e.handle as any).permission)) {
                    setAllowed(false);
                    setRequiredPermissions((prev) => prev.add((e.handle as any).permission));
                } else {
                    setAllowed(true);
                }
            });
            setLoading(false);
        }
    }, [currentUser, matches, setAllowed, setRequiredPermissions, setLoading, hasPermission]);

    useEffect(() => {
        if (!loading && !allowed) {
            enqueueSnackbar(`The page you're trying to access requires the following permissions: ` + [...requiredPermissions].join(', '), {
                variant: 'error',
            });
            let navDataItem = navData.find((x) => !x.permission || hasPermission(x.permission));
            navDataItem ? navigate(navDataItem.link) : navigate(-1);
        }
    }, [allowed, navigate, requiredPermissions, loading, hasPermission]);

    return <Layout>{loading || !allowed ? null : <Outlet />}</Layout>;
};

export default withAuthenticationRequired(ProtectedRoute);
