import { Dialog, DialogProps, DialogTitle, LinearProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import './Modal.css';
import React from 'react';

interface ModalProps extends DialogProps {
    children: any;
    onClose: () => void;
    open: boolean;
    title?: string;
    TitleComponent?: React.ElementType;
    style?: any;
    contentStyle?: any;
    sx?: any;
    isLoading?: boolean;
}

const Modal = ({ onClose, open, title, TitleComponent, style, contentStyle, children, isLoading, ...props }: ModalProps) => {
    return (
        <Dialog fullWidth maxWidth={'sm'} className="modal" onClose={onClose} open={open} {...props}>
            {isLoading && <LinearProgress sx={{ display: isLoading ? 'block' : 'none' }} variant={'indeterminate'} />}
            {title != null && <DialogTitle>{title}</DialogTitle>}
            {TitleComponent != null && <TitleComponent />}
            <Grid sx={{ p: '1.5rem', pt: 0, ...contentStyle }}>{children}</Grid>
        </Dialog>
    );
};

export default Modal;
