import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import {
    Button,
    CircularProgress,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers-pro';
import axios from 'axios';
import dayjs from 'dayjs';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplicationContext } from '../../../ApplicationContext';
import { STATES } from '../../../lib/constants';
import FileUpload, { FileTypes } from '../../Inputs/FileUpload';
import NumberInput from '../../Inputs/NumberInput';

const REQUIRED_FIELDS = ['name'];

const CommunityInformation = ({ community, isLoading }) => {
    const navigate = useNavigate();
    const confirm = useConfirm();
    const { selectedCompanyId, companies } = useApplicationContext();
    const [communityData, setCommunityData] = useState(community);
    const [image, setImage] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setCommunityData(community);
    }, [community]);

    const onFieldChange = (key, value) => {
        setIsDirty(true);
        setCommunityData((prev) => ({ ...prev, [key]: value }));
    };

    const saveChanges = useCallback(async () => {
        setIsSaving(true);

        if (REQUIRED_FIELDS.some((f) => !communityData[f])) {
            enqueueSnackbar('Please fill out all required fields', { variant: 'error' });
            setIsSaving(false);
            return;
        } else if ([communityData.ownerCompanyId, communityData.operatorCompanyId].some((id) => id === undefined)) {
            enqueueSnackbar('Owner and operator must be selected', { variant: 'error' });
            setIsSaving(false);
            return;
        } else if (![communityData.ownerCompanyId, communityData.operatorCompanyId].some((id) => id === selectedCompanyId)) {
            enqueueSnackbar('Owner or operator must match the selected company', { variant: 'error' });
            setIsSaving(false);
            return;
        }

        let url: string;
        const data = { ...communityData };

        if (image) {
            const formData = new FormData();
            formData.append('image', image);

            url = `/Community/${selectedCompanyId}/Communities/${community.id}/UploadImage`;
            await axios
                .post(url, formData)
                .then((response) => {
                    data.imageURL = response.data;
                })
                .catch(() => {
                    enqueueSnackbar('An error occurred while uploading the image', { variant: 'error' });
                })
                .finally(() => setIsSaving(false));
        }

        url = `/Community/${selectedCompanyId}/Communities`;
        await axios
            .post(url, data)
            .then(() => {
                navigate('/admin/communities');
            })
            .finally(() => setIsSaving(false));
    }, [communityData, image, navigate, selectedCompanyId, community.id]);

    const archiveCommunity = useCallback(async () => {
        await confirm({
            title: 'Archive Community',
            description: 'Are you sure you want to archive this community?',
            confirmationText: 'Yes',
            cancellationText: 'Cancel',
            confirmationButtonProps: { variant: 'text', color: 'error' },
        })
            .then(async () => {
                setIsArchiving(true);
                const url = `/Community/${selectedCompanyId}/Communities/${community.id}`;
                return await axios
                    .delete(url)
                    .then(() => {
                        navigate('/admin/communities');
                    })
                    .catch(() => {
                        enqueueSnackbar('An error occurred while archiving the community', { variant: 'error' });
                    });
            })
            .catch(() => {})
            .finally(() => setIsArchiving(false));
    }, [community, navigate, selectedCompanyId, confirm]);

    const cancelChanges = async () => {
        if (isDirty) {
            await confirm({
                title: 'Unsaved Changes',
                description: 'Are you sure you want to leave this page? Your changes will not be saved.',
                confirmationText: 'Yes',
                cancellationText: 'No',
            })
                .then(() => {
                    navigate('/admin/communities');
                })
                .catch(() => {});
        } else {
            navigate('/admin/communities');
        }
    };

    return (
        <Grid
            container
            direction={'column'}
            sx={{
                mt: 2,
                background: '#ffffff',
            }}
        >
            {isLoading || !community ? (
                <Stack spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <Skeleton variant="circular" width={75} height={75} />
                        <Stack spacing={2} sx={{ flex: 1 }}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </Stack>
                    </Stack>
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                </Stack>
            ) : (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
                            <Button variant={'text'} onClick={archiveCommunity} color={'error'}>
                                {isArchiving && <CircularProgress color={'inherit'} size={20} sx={{ mr: 1 }} />}
                                Archive
                            </Button>
                            <Button variant={'outlined'} onClick={cancelChanges}>
                                Cancel
                            </Button>
                            <Button variant={'contained'} color={'primary'} onClick={saveChanges} disabled={isSaving}>
                                {isSaving && <CircularProgress color={'inherit'} size={20} sx={{ mr: 1 }} />}
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            border: '1px solid #dee2e6',
                            borderRadius: 2,
                            padding: '1rem',
                            m: 1,
                        }}
                    >
                        <Grid xs={12} md={4}>
                            <FileUpload
                                defaultURL={community.imageURL}
                                fileTypes={[FileTypes.PNG, FileTypes.JPEG]}
                                onChange={(files) => {
                                    if (files.length < 1) {
                                        setImage(null);
                                    } else {
                                        setImage(files[0]);
                                        setIsDirty(true);
                                    }
                                }}
                                DropzoneIcon={ApartmentOutlinedIcon}
                                sx={{ height: 224 }}
                            />
                        </Grid>
                        <Grid container xs={12} md={8}>
                            <Grid xs={12} md={6}>
                                <Stack spacing={2}>
                                    <TextField
                                        required
                                        size={'small'}
                                        label={'Community Name'}
                                        onChange={(e) => onFieldChange('name', e.target.value)}
                                        defaultValue={community.name}
                                    />
                                    <TextField
                                        size={'small'}
                                        label={'Code'}
                                        onChange={(e) => onFieldChange('code', e.target.value)}
                                        defaultValue={community.code}
                                    />
                                    <NumberInput
                                        size={'small'}
                                        label={'Latitude'}
                                        onChange={(v) => onFieldChange('latitude', v)}
                                        defaultValue={community.latitude}
                                    />
                                    <NumberInput
                                        size={'small'}
                                        label={'Longitude'}
                                        onChange={(v) => onFieldChange('longitude', v)}
                                        defaultValue={community.longitude}
                                    />
                                </Stack>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Stack spacing={2}>
                                    <TextField
                                        size={'small'}
                                        label={'Address'}
                                        onChange={(e) => onFieldChange('address', e.target.value)}
                                        defaultValue={community.address}
                                        inputProps={{
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                    <TextField
                                        size={'small'}
                                        label={'City'}
                                        onChange={(e) => onFieldChange('city', e.target.value)}
                                        defaultValue={community.city}
                                        inputProps={{
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                    <Autocomplete
                                        size={'small'}
                                        autoComplete
                                        id={'company-state-dropdown'}
                                        options={[{ label: 'None', value: '' }, ...STATES]}
                                        getOptionLabel={(option) => (option.value ? `${option.value} - ${option.label}` : option.label)}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        onChange={(_, state) => onFieldChange('state', state?.value)}
                                        defaultValue={STATES.find((s) => s.value === community.state) || null}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    label={'State'}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                    <NumberInput
                                        size={'small'}
                                        label={'Zip Code'}
                                        onChange={(v) => onFieldChange('zip', v)}
                                        defaultValue={community.zip}
                                        inputProps={{
                                            autoComplete: 'new-password',
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    fullWidth
                                    size={'small'}
                                    label={'Website'}
                                    onChange={(e) => onFieldChange('website', e.target.value)}
                                    defaultValue={community.website}
                                />
                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant={'h6'} color={'grey.700'}>
                                Property
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Stack spacing={2}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    label={'Date Opened'}
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(date) => onFieldChange('openingDate', dayjs(date).format('YYYY-MM-DD'))}
                                    defaultValue={community.openingDate ? dayjs(community.openingDate) : null}
                                />
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    label={'Date Acquired'}
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(date) => onFieldChange('acquiredDate', dayjs(date).format('YYYY-MM-DD'))}
                                    defaultValue={community.acquiredDate ? dayjs(community.acquiredDate) : null}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Stack spacing={2}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    label={'Date Renovated'}
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(date) => onFieldChange('renovatedDate', dayjs(date).format('YYYY-MM-DD'))}
                                    defaultValue={community.renovatedDate ? dayjs(community.renovatedDate) : null}
                                />
                                <NumberInput
                                    fullWidth
                                    size={'small'}
                                    label={'Site Size (Acreage)'}
                                    onChange={(v) => onFieldChange('siteSize', v)}
                                    defaultValue={community.siteSize}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Stack spacing={2}>
                                <NumberInput
                                    fullWidth
                                    size={'small'}
                                    label={'Total SqFt'}
                                    onChange={(v) => onFieldChange('sqFt', v)}
                                    defaultValue={community.sqFt}
                                />
                                <NumberInput
                                    fullWidth
                                    size={'small'}
                                    label={'Total Floors'}
                                    onChange={(v) => onFieldChange('floors', v)}
                                    defaultValue={community.floors}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Stack spacing={2}>
                                <TextField
                                    fullWidth
                                    size={'small'}
                                    label={'Parking'}
                                    onChange={(e) => onFieldChange('parking', e.target.value)}
                                    defaultValue={community.parking}
                                    placeholder={'Surface/Garage'}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant={'h6'} color={'grey.700'}>
                                Grouping
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Portfolio'}
                                onChange={(e) => onFieldChange('portfolio', e.target.value)}
                                defaultValue={community.portfolio}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Region'}
                                onChange={(e) => onFieldChange('region', e.target.value)}
                                defaultValue={community.region}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Services'}
                                onChange={(e) => onFieldChange('services', e.target.value)}
                                defaultValue={community.services}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant={'h6'} color={'grey.700'}>
                                Owner / Operator
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <FormControl fullWidth margin="dense" size="small">
                                <InputLabel id="owner-edit-select-label">Owner</InputLabel>
                                <Select
                                    id="owner-edit-select"
                                    labelId="owner-edit-select-label"
                                    label="Owner"
                                    onChange={(e: SelectChangeEvent) => onFieldChange('ownerCompanyId', Number(e.target.value))}
                                    defaultValue={community.ownerCompanyId || null}
                                    renderValue={(value) => {
                                        let company = companies.find((c) => c.id === Number(value));
                                        return company?.name;
                                    }}
                                >
                                    {companies
                                        .filter((company) => company.isOwner)
                                        .map((company) => (
                                            <MenuItem key={company.id} value={company.id} sx={{ justifyContent: 'space-between' }}>
                                                <Typography>{company.name}</Typography>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <FormControl fullWidth margin="dense" size="small">
                                <InputLabel id="operator-edit-select-label">Operator</InputLabel>
                                <Select
                                    id="operator-edit-select"
                                    labelId="operator-edit-select-label"
                                    label="Operator"
                                    onChange={(e: SelectChangeEvent) => onFieldChange('operatorCompanyId', Number(e.target.value))}
                                    defaultValue={community.operatorCompanyId || null}
                                    renderValue={(value) => {
                                        let company = companies.find((c) => c.id === Number(value));
                                        return company?.name;
                                    }}
                                >
                                    {companies
                                        .filter((company) => !company.isOwner)
                                        .map((company) => (
                                            <MenuItem key={company.id} value={company.id} sx={{ justifyContent: 'space-between' }}>
                                                <Typography>{company.name}</Typography>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant={'h6'} color={'grey.700'}>
                                Review Sites
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'APlaceForMom Review URL'}
                                onChange={(e) => onFieldChange('aPlaceForMomURL', e.target.value)}
                                defaultValue={community.aPlaceForMomURL}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Caring Review URL'}
                                onChange={(e) => onFieldChange('caringURL', e.target.value)}
                                defaultValue={community.caringURL}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default CommunityInformation;
